import { clsx, type ClassValue } from "clsx";
import * as d3 from "d3";
import { jStat } from "jstat-esm";
import { twMerge } from "tailwind-merge";


export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function slow<T>(duration: number): (data: T) => Promise<T> {
  return function (data: T) {
    return new Promise<T>((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, duration);
    });
  };
}

export const isObject = (obj: unknown) =>
  obj !== null && typeof obj === "object" && !Array.isArray(obj);

export const isEmptyObject = (obj: unknown) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export function getKeyByValue({ object, value }: { object: Record<string, unknown>, value: unknown }) {
  return Object.keys(object).find(key => object[key] === value);
}

// Helper function to determine the type of the attribute
// from the raw type string provided by the API
export function getTypeFromRawType(rawtype: string): "number" | "text" | "list" | "boolean" {
  switch (rawtype) {
    case "<class 'int'>":
      return "number";
    case "<class 'str'>":
      return "text";
    case "<class 'list'>":
      return "list";
    case "<class 'bool'>":
      return "boolean";
    case "<class 'float'>":
      return "number";
    case "<class 'supporting.customermanagementhandler.UniqueTogetherList'>":
      return "list";
    default:
      return "text";
  }
}


export function linearRegressionStats(data: { x: number; y: number }[]) {
  const n = data.length;
  const xMean = d3.mean(data, d => d.x)!;
  const yMean = d3.mean(data, d => d.y)!;

  const xDiff = data.map(d => d.x - xMean);
  const yDiff = data.map(d => d.y - yMean);

  const SSxy = d3.sum(data, (d, i) => xDiff[i] * yDiff[i]);
  const SSxx = d3.sum(xDiff, d => d * d);

  const slope = SSxy / SSxx;
  const intercept = yMean - slope * xMean;

  const yPred = data.map(d => intercept + slope * d.x);

  const SSres = d3.sum(data.map((d, i) => (d.y - yPred[i]) ** 2));
  const SStot = d3.sum(data.map(d => (d.y - yMean) ** 2));

  const R2 = 1 - SSres / SStot;

  // Calculate standard error and p-value
  const SE = Math.sqrt(SSres / (n - 2)) / Math.sqrt(SSxx);
  const t = slope / SE;
  const df = n - 2;

  // Two-tailed p-value from t-distribution
  const p = 2 * (1 - jStat.studentt.cdf(Math.abs(t), df));

  return { slope, intercept, R2, p };
}
